import React from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCode} from "@fortawesome/free-solid-svg-icons";
import {Link} from "gatsby";


import JanaGavacovaPavolBereza from "../Events/JanaGavacovaPavolBereza";
import Kristan from "../Events/Kristan";
import JankaKolesarova from "../Events/JankaKolesarova";
import MolcanyiovaBereza from "../Events/MolcanyiovaBereza";
import AfterteeSession from "../Events/AfterteeSession";
import RigoStubniakValicekTrio from "../Events/RigoStubniakValicekTrio";

import {StaticImage} from "gatsby-plugin-image";
import Swingless from "../Events/Swingless";
import SKDK from "../Events/SKDK";
const News = () => (
    <section className={"news bg-light-border"}>

        <div className="container">

            <h2 className={"text-center mb-4"} id={"novinky"}>Novinky
                <span>Akcie & koncerty</span>
            </h2>

            <p className="text-center">
                Aktuálne ponuky, akcie a koncerty nájdete vždy tu.
            </p>
            {/*<Swingless/>*/}
            {/*<SKDK/>*/}
            <div className="col-lg-8 col-12 mx-auto text-center mb-3">
                {/*PROGRAM*/}
                <StaticImage imgClassName={"imgHoverEffect"} className={"mb-4"}
                             src="../../assets/custom-style/images/program/Jazzenda-04-2025.webp"
                             alt="program April 2025"/>

                {/*DOVOLENÁ*/}
                {/*<StaticImage imgClassName={"imgHoverEffect"} className={"mb-4"} src="../../assets/custom-style/images/DOVOLENKA.webp" alt="dovolená 2024"/>*/}
            </div>

            {/*<div className="card border-0 shadow mb-4">*/}
            {/*    <div className="card-body">*/}
            {/*        <h3>Máme otvorené</h3>*/}
            {/*        <span className="badge bg-secondary">NEWS </span>*/}
            {/*        <p>Zastavte sa na dobrú kávu, drink, alebo čapované pivo Svijany.</p>*/}
            {/*        <p><strong>Nájdete nás v Starom meste - Biela 5, Bratislava </strong></p>*/}
            {/*    </div>*/}
            {/*</div>*/}

            {/*   <div className="card border-0 shadow">
                <div className="card-body">

                    <h3>Koncerty január 2022</h3>
                    <span className="badge bg-secondary">JAZZ </span>
                    <p>Vzhľadom na vládne nariadenie nie sú na mesiac január naplánované žiadne koncerty.</p>
                </div>
            </div>*/}

        </div>
    </section>

);

export default News

